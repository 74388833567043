var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"landingPage"},[(_vm.page)?[_c('div',{staticClass:"container full featured"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"text"},[_c('h1',[_vm._v(_vm._s(_vm.page.title))]),(_vm.page.intro)?_c('Blocks',{attrs:{"blocks":_vm.page.intro,"type":"page","element":"index"}}):_vm._e(),_vm._l((_vm.page.buttons),function(button){return _c('div',{key:button._key,staticClass:"flex flex-group"},[_c('a',{staticClass:"btn",class:{ 'btn-w-icon': !button.small },attrs:{"href":button.href,"target":button.blank ? '_blank' : '_self'}},[(button.small)?_c('span',[_vm._v(" "+_vm._s(button.title)+" ")]):_c('div',{staticClass:"text"},[_c('h2',[_vm._v(_vm._s(button.title))]),(button.text)?_c('p',[_vm._v(_vm._s(button.text))]):_vm._e()]),_c('i',{staticClass:"gn gn-icons arrow-link"})])])})],2),_vm._m(0)])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"wrapper"},[_c('Blocks',{attrs:{"blocks":_vm.page.body,"type":"page","element":"index"}}),_c('div',{staticClass:"grid padding-top"},_vm._l((_vm.page.posts),function(post){return _c('div',{key:post._key,staticClass:"col-4 col-md-6 col-sm-12"},[_c('div',{staticClass:"badge"},[_c('div',{staticClass:"top"},[_c('router-link',{staticClass:"image",style:({
                    backgroundImage: ("url('" + (_vm.getImageUrl(post.mainImage)) + "')"),
                  }),attrs:{"to":{
                    name: post._type === 'post' ? 'post' : 'page',
                    params: { slug: _vm.getSlug(post) },
                  }}})],1),_c('div',{staticClass:"bottom"},[(
                    (post.categories && post.categories.length > 0) ||
                      (post.sponsor && post.sponsor.info_overview)
                  )?_c('div',{staticClass:"flex flex-group flex-wrap"},[_vm._l((post.categories),function(cat){return _c('router-link',{key:cat._key,staticClass:"pill",attrs:{"to":{ name: 'category', params: { slug: _vm.getSlug(cat) } },"href":""}},[_vm._v(_vm._s(cat.title))])}),(post.sponsor && post.sponsor.info_overview)?_c('span',{staticClass:"pill"},[_vm._v(" "+_vm._s(post.sponsor.info_overview)+" ")]):_vm._e()],2):_vm._e(),_c('h2',[_c('router-link',{attrs:{"to":{
                      name: post._type === 'post' ? 'post' : 'page',
                      params: { slug: _vm.getSlug(post) },
                    }}},[_vm._v(_vm._s(post.title))])],1),(post.intro)?_c('p',[_c('router-link',{attrs:{"to":{
                      name: post._type === 'post' ? 'post' : 'page',
                      params: { slug: _vm.getSlug(post) },
                    }}},[_vm._v(_vm._s(_vm.getIntro(post)))])],1):_vm._e()])])])}),0)],1)])]:_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"image"},[_c('img',{attrs:{"src":require("../../assets/img/frontpage-featured-image-6.webp")}})])}]

export { render, staticRenderFns }